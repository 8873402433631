<template>
  <div>
    <Loading v-if="isLoading" />

    <v-card v-if="validBooking" tile elevation="0" flat v-show="!isLoading">
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <!-- Mapa de google --->
      <gmap
        v-if="validCoords"
        :origin="map.origin"
        :destination="map.destination"
      />

      <v-card-title class="pb-0 mb-0"
        >Client: {{ clientName }}

        <v-spacer></v-spacer>

        <v-btn icon @click.prevent="chat(booking.id)" v-if="!isPending">
          <v-icon>mdi-message-text-outline</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div class="my-2">
          ID: {{ booking.serviceNumber }}
          <v-btn
            depressed
            rounded
            x-small
            :color="bookingStatus.color"
            :key="bookingStatus.label"
            >{{ bookingStatus.label }}</v-btn
          ><br />
          {{ booking.pickupDatetime | moment("D MMM YYYY") }} at
          {{ booking.pickupDatetime | moment("HH:mm") }} hrs <br />
          {{ booking.reservation.adults }} PAX <br />
          {{ booking.serviceType.name }}<br />
        </div>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-list two-line>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">
              mdi-map-marker-radius-outline
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-subtitle>{{
              $t("booking.pickupOn")
            }}</v-list-item-subtitle>
            <v-list-item-title
              >{{ booking.pickup.name }} {{ $t("booking.at") }}
              {{ booking.pickupDatetime | moment("HH:mm") }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">
              mdi-map-marker-check-outline
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-subtitle>{{
              $t("booking.dropoffAt")
            }}</v-list-item-subtitle>
            <v-list-item-title>{{ booking.dropoff.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template v-if="booking.trip !== 'P2P'">
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo">
                mdi-airplane
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-subtitle>{{
                $t("booking.flightNumber")
              }}</v-list-item-subtitle>
              <v-list-item-title>{{ booking.flightNumber }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo" v-if="booking.trip === 'H2A'">
                mdi-airplane-takeoff
              </v-icon>
              <v-icon color="indigo" v-if="booking.trip === 'A2H'">
                mdi-airplane-landing
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-subtitle>{{
                $t("booking.fligthTime")
              }}</v-list-item-subtitle>
              <v-list-item-title
                >{{ booking.flightDatetime | moment("D MMM YYYY") }}
                {{ $t("booking.at") }}
                {{ booking.flightDatetime | moment("HH:mm") }}
                hrs</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </template>

        <!-- <v-list-item>
					<v-list-item-icon>
						<v-icon color="indigo">
							mdi-clock-outline
						</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title>At time</v-list-item-title>
						<v-list-item-subtitle
							>Fligth Status</v-list-item-subtitle
						>
					</v-list-item-content>
				</v-list-item> -->

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">
              mdi-information
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-subtitle>{{
              $t("booking.notes")
            }}</v-list-item-subtitle>
            <v-list-item-title>{{
              booking.supplierNotes || "N/A"
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>

    <!-- Dialogos -->
    <v-dialog v-model="dialog.active" persistent max-width="290">
      <v-card :color="colorDialog" :dark="dialog.error">
        <v-card-title>{{ labelDialog }}</v-card-title>
        <v-card-text>{{ dialog.changeStatusAlert }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            text
            @click="dialog.active = false"
            v-if="!dialog.error"
          >
            {{ $t("booking.dialog.btnCancel") }}
          </v-btn>
          <v-btn text @click="dialog.action">
            {{ $t("booking.dialog.btnToAccept") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import LatLon from "geodesy/latlon-ellipsoidal-vincenty.js";

const gmap = () =>
  import(/* webpackChunkName: "Gmap" */ "@/components/Gmap.vue");
const Loading = () =>
  import(/* webpackChunkName: "Loading" */ "@/components/loading.vue");

export default {
  components: { gmap, Loading },
  data: () => ({
    isLoading: true,
    validBooking: false,
    booking: {},
    map: {
      origin: { lat: 0, lng: -0 },
      destination: { lat: 0, lng: -0 },
    },
    dialog: {
      active: false,
      label: null,
      changeStatusAlert: "",
      action: () => {},
      error: false,
    },
    statesName: [
      "pending",
      "onWayToPickup",
      "atPickup",
      "onWayToDropoff",
      "finished",
    ],
    bookingStatusOpts: {},
    bookingStatus: {},
    steps: {},
  }),
  methods: {
    ...mapActions(['initGpsTracking']),
    ...mapMutations("ui", {
      addNavigationItem: "addNavigationItem",
      clearNavigationItems: "clearNavigationItems",
      removeNavigationItem: "removeNavigationItem",
      openSnackbar: "openSnackbar",
    }),
    ...mapMutations("services", {
      setService: "setService",
      changeStatus: "changeStatus",
      checkin: "checkin",
      addGpcLocation: "addGpcLocation",
    }),
    ...mapActions("ui", {
      disabledNavigation: "disabledNavigation",
      enableddNavigation: "enableddNavigation",
      openQRReaderOnlyEvent: "openQRReaderOnlyEvent",
      activeBackBtn: "activeBackBtn",
      showProgress: "showProgress",
      hideProgress: "hideProgress",
      resetQR: "resetQR",
    }),
    ...mapActions("services", {
      resetService: "resetService",
    }),
    fetchModel() {
      this.isLoading = true;
      this.$axios
        .get(`driver/transport-services/${this.$route.params.id}`)
        .then((res) => {
          this.booking = { ...res.data };
          this.validBooking = true;

          this.map.origin = {
            lat: parseFloat(this.booking.pickup.latitude),
            lng: parseFloat(this.booking.pickup.longitude),
          };
          this.map.destination = {
            lat: parseFloat(this.booking.dropoff.latitude),
            lng: parseFloat(this.booking.dropoff.longitude),
          };

          this.bookingStatus = this.bookingStatusOpts[this.booking.status];

          // Almacenar el indice correcto
          this.changeStatus(
            parseInt(this.findIndexForStatus(this.booking.trackingStatus))
          );

          // Validar que las coordenadas sean correctas
          if (this.isValidCord()) {
            if (
              this.booking.trackingStatus !==
                this.statesName[this.statesName.length - 1] &&
              this.booking.trackingStatus !== "noShow" &&
              this.booking.status === "active"
            ) {
              /* if (this.existActiveService()) {
								if (this.isActiveService()) {
									// Inicializar el navigation
									this.initNavigation();
								} else {
									this.openSnackbar(
										this.$t(
											"booking.txtErrorServiceInProcess"
										)
									);
								}
							} else {
								// Inicializar el navigation
								this.initNavigation();
							} */

              // Inicializar el navigation
              this.initNavigation();
            }
          } else {
            this.openSnackbar(this.$t("booking.txtErrorCord"));
          }

          this.isLoading = false;
        });
    },
    initNavigation() {
      if (this.existActiveService()) {
        //	if (this.isActiveService()) {
        // Validar si ya esta verificado
        if (this.isCheckin()) {
          this.checkinAction();
        } else {
          this.tryNextStep(this.stateIndex + 1);
        }
        /* 			} else {
					// Notificar que ya se cuenta con otro activo
					this.openSnackbar(
						this.$t("booking.txtErrorServiceInProcess")
					);
				} */
      } else {
        // Validar si ya esta verificado
        if (this.isCheckin()) {
          this.checkinAction();
        } else {
          this.tryNextStep(this.stateIndex + 1);
        }
        // this.tryNextStep(this.stateIndex + 1);
      }
    },
    checkinAction() {
      // Checkin
      this.checkin();
      // Almacenar el Servicio
      this.setService(this.booking);
      // Asignar el ID del status
      this.changeStatus(3);
      // Pasar al paso de checkin
      this.tryNextStep(3);
    },
    tryNextStep(index) {
      if (index <= this.statesName.length - 1) {
        const btn = this.steps[this.statesName[index]];

        // Vaciar la barra de navegacion
        this.clearNavigationItems();

        // Verificar que no se requiera validar.
        if (
          this.statesName[index] === "onWayToDropoff" &&
          !this.serviceCheckin
        ) {
          this.addBtn(
            {
              btnText: "Check In",
              btnIcon: "mdi-account-check-outline",
            },
            null,
            () => {
              this.openQRReaderOnlyEvent();
              // Esperar la respuesta
              this.$bus.once("onDecode", (data) => {
                if (
                  this.id === parseInt(data.id) ||
                  this.booking.id === parseInt(data.id) ||
                  data.check
                ) {
                  // Vaciar la barra de navegacion
                  this.clearNavigationItems();
                  // Indicar que ya se hizo checking
                  this.checkin();
                  // Almacenar el evento
                  this.saveGpcLocationStatus("Checkin");
                  // Agregar el boton
                  this.addBtn(this.steps.onWayToDropoff, index);
                } else {
                  // Notificar el evento
                  this.openSnackbar(this.$t("booking.txtErrorNoMatchQr"));
                }
              });
            },
            true
          );

          // Boton de no show
          this.addBtn(
            {
              btnText: "Not Show",
              btnIcon: "mdi-eye-off-outline",
              changeStatusAlert: this.$t("booking.txtErrorNoShow"),
            },
            null,
            () => {
              const timeBooking = new Date(
                this.booking.pickupDatetime
              ).getTime();
              const currentTime = new Date().getTime();

              // Validar el tiempo de espera segun
              if (this.booking.trip === "A2H") {
                if (currentTime - timeBooking > 60000 * 60) {
                  this.actionNoShow();
                  // Disabled Navigation
                  this.disabledNavigation();
                } else {
                  this.dialogNoshowIncorrect(this.$t("booking.txtError60Min"));
                }
              } else if (
                this.booking.trip === "H2A" ||
                this.booking.trip === "P2P"
              ) {
                if (currentTime - timeBooking > 60000 * 15) {
                  this.actionNoShow();
                  // Disabled Navigation
                  this.disabledNavigation();
                } else {
                  this.dialogNoshowIncorrect(this.$t("booking.txtError15Min"));
                }
              } else {
                this.dialog.changeStatusAlert = this.$(
                  "booking.txtErrorNoTrip"
                );
                this.dialog.active = true;
              }
            }
          );
        } else if (this.statesName[index] === "atPickup") {
          // Agregar el elemento correspondiente
          this.addBtn(btn, index, async () => {
            if (process.env.VUE_APP_DEBUG) {
              this.dialog.active = false;
              this.actionStep(index);
            } else {
              try {
                /*  const position = await this.getLocation();

                 const dist = this.distance(
                  parseFloat(position.coords.latitude),
                  parseFloat(position.coords.longitude),
                  parseFloat(this.booking.pickup.latitude),
                  parseFloat(this.booking.pickup.longitude)
                );
                //valida que el driver este a 1km de distancia del pickup
               		if (
									process.env.VUE_APP_GEOVALIDATION ===
										"true" &&
									dist > 5000
								) {
									this.openSnackbar(
										this.$t("booking.txtErrorDistance")
									);
								} else { */
                this.dialog.active = false;
                this.actionStep(index);
                //}
              } catch (error) {
                if (error.gps) {
                  this.openSnackbar(this.$t("booking.txtGpsError"));
                }
              }
            }
          });
        } else {
          // Agregar el elemento correspondiente
          this.addBtn(btn, index);
        }

        // Mostrar el navigationButtom
        this.enableddNavigation();
      } else {
        // Vaciar la barra de navegacion
        this.clearNavigationItems();
        // Desabilitat el menu
        this.disabledNavigation();
      }
    },
    dialogNoshowIncorrect(text) {
      this.dialog.active = false;
      this.dialog.label = this.$t("booking.txtNoShowNotAllowed");
      this.dialog.changeStatusAlert = text + this.$t("booking.txtNoShowPlus");
      this.dialog.error = true;
      this.dialog.active = true;
      this.dialog.action = () => {
        this.dialog.active = false;
      };
    },
    async actionNoShow() {
      this.resetQR();
      this.resetService();
      // Mandar el aviso al servidor
      // await this.doNextStep("noShow");
      this.$router.push({
        name: "noshow",
        params: { id: this.booking.id },
      });
    },
    async validGps() {
      try {
        await this.getLocation();
      } catch (error) {
        if (!error.gps) {
          this.openSnackbar(this.$t("booking.txtGpsError"));
        }
        return false;
      }
      return true;
    },
    async actionStep(index) {
      try {
        // Mostrar la barra de progreso
        this.showProgress();

        let position = null;
        try {
          // Recuperar la posicion actual
          position = await this.getLocation();
        } catch (error) {
          if (!error.gps) {
            this.openSnackbar(this.$t("booking.txtGpsError"));
          }
          this.hideProgress();
          return; // Detener el evento
        }

        // Guardar la ubicacion
        await this.doNextStep(this.statesName[index], {
          latitude: position.latitude,
          longitude: position.longitude,
          velocityKmh: position.speed || 0,
        });

        // Registrar la ubicacion
        this.saveGpcLocationStatus(this.statesName[index]);
        // Verificar si ya no es el ultimo status
        if (this.statesName[index] === "finished") {
          // Reset
          this.resetData();
        } else if (this.statesName[index] === "onWayToPickup") {
          // Almacenar el Servicio
          this.setService(this.booking);
        } else {
          // Asignar el ID del status
          this.changeStatus(index + 1);
        }
        this.tryNextStep(index + 1);
      } catch (error) {
        if (error.gps) {
          this.openSnackbar(this.$t("booking.txtGpsError"));
        } else {
          let errorTxt = this.$t("booking.txtErrorChangeStatus");
          if (error.response.status === 403) {
            const data = error.response.data;
            if (data.errorCode === 20) {
              errorTxt = `${this.$t("booking.txtErrorServiceInProcess")} ${
                data.serviceNumber
              }`;
            }
          }
          this.openSnackbar(errorTxt);
        }
      }
    },
    addBtn(btn, index, action, notDialog) {
      // Agregar el boton al navigationButtom
      this.addNavigationItem({
        name: btn.btnText,
        icon: btn.btnIcon,
        type: "action",
        action: () => {
          // Validar si requiere lanzar o no el dialogo
          if (notDialog) {
            if (action) {
              action();
            } else {
              this.actionStep(index);
            }
          } else {
            this.dialog = {
              ...this.dialog,
              ...btn,
              ...{
                action: () => {
                  this.dialog.active = false;
                  if (action) {
                    action();
                  } else {
                    this.actionStep(index);
                  }
                },
                changeStatusAlert: this.stringReplace(btn.changeStatusAlert),
              },
              label: null,
              error: false,
              active: true,
            };
          }
        },
      });
    },
    doNextStep(status, tracking) {
      const url = `driver/transport-services/${this.booking.id}/change-tracking-status`;
      return new Promise((rr, ff) => {
        this.$axios
          .put(url, { status, tracking })
          .then(async (resp) => {
            // Consultar el estado
            this.$axios
              .get(`driver/transport-services/${this.$route.params.id}`)
              .then((res) => {
                this.bookingStatus = this.bookingStatusOpts[res.data.status];
                // Asignar el mismo status
                this.booking.trackingStatus = resp.data.trackingStatus;
                // Indicar resp correcta
                rr(resp.data);
              })
              .catch((err) => {
                ff(err);
              });
          })
          .catch((err) => {
            ff(err);
          })
          .finally(() => {
            this.hideProgress();
          });
      });
    },
    findIndexForStatus(state) {
      for (let i in this.statesName) {
        if (this.statesName[i] === state) {
          return i;
        }
      }
    },
    isActiveService() {
      if (this.id === this.booking.id) {
        return true;
      } else {
        return false;
      }
    },
    isValidCord() {
      return this.booking.pickup.address !== null;
    },
    existActiveService() {
      if (this.id !== null) {
        return true;
      } else {
        return false;
      }
    },
    isCheckin() {
      if (this.$route.query.checkin) {
        return true;
      } else {
        return false;
      }
    },
    stringReplace(text) {
      return text
        .replace("$pickup", this.booking.pickup.name)
        .replace("$dropoff", this.booking.dropoff.name);
    },
    chat(id) {
      setTimeout(() => {
        this.$router.push({ params: { id }, name: "Chat" });
      }, 500);
    },
    async saveGpcLocationStatus(status) {
      const position = await this.getLocation();
      // Guardar en store
      this.addGpcLocation({
        status,
        latitude: position.latitude,
        longitude: position.longitude,
      });
    },
    async getLocation() {
      await this.initGpsTracking();
      return this.geolocationData;
    },
    resetData() {
      this.resetQR();
      this.resetService();
      this.disabledNavigation();
      this.dialog = {
        active: false,
        label: "",
        changeStatusAlert: "",
        action: () => {},
      };
    },
    distance(lat, lon, lat2, lon2) {
      const p1 = new LatLon(lat, lon);
      const p2 = new LatLon(lat2, lon2);
      const d = parseFloat(p1.distanceTo(p2).toPrecision(4));
      return d;
    },
    toRad(Value) {
      return (Value * Math.PI) / 180;
    },
  },
  computed: {
    ...mapState(["geolocationData"]),
    ...mapState("services", {
      stateIndex: (state) => state.stateIndex,
      service: (state) => state.data,
      id: (state) => state.id,
      serviceCheckin: (state) => state.checkin,
    }),
    isPending() {
      return this.booking.trackingStatus === "pending";
    },
    validCoords() {
      return (
        this.validBooking &&
        this.booking.pickup.latitude !== null &&
        this.booking.pickup.longitude !== null &&
        this.booking.dropoff.latitude !== null &&
        this.booking.dropoff.longitude !== null
      );
    },
    colorDialog() {
      if (this.dialog.error) {
        return "red darken-3";
      }
      return "white";
    },
    labelDialog() {
      if (this.dialog.label === null) {
        return this.$t("booking.txtChangeStatus");
      } else {
        return this.dialog.label;
      }
    },
    clientName() {
      let name =
        this.booking.reservation.client.firstName +
        " " +
        this.booking.reservation.client.lastName;
      if (name.length > 17) {
        return name.substring(0, 17) + "...";
      } else {
        return name;
      }
    },
  },
  mounted() {
    // Asignaciones
    this.steps = {
      pending: {
        status: "pending",
        btnText: this.$t("booking.steps.pending.btnText"),
        btnIcon: "mdi-car-estate",
        label: this.$t("booking.steps.pending.txtLabel"),
        changeStatusAlert: this.$t(
          "booking.steps.pending.txtChangeStatusAlert"
        ),
      },
      onWayToPickup: {
        status: "onWayToPickup",
        btnText: this.$t("booking.steps.onWayToPickup.btnText"),
        btnIcon: "mdi-car-estate",
        label: this.$t("booking.steps.onWayToPickup.txtLabel"),
        changeStatusAlert: this.$t(
          "booking.steps.onWayToPickup.txtChangeStatusAlert"
        ),
      },
      atPickup: {
        status: "atPickup",
        btnText: this.$t("booking.steps.atPickup.btnText"),
        btnIcon: "mdi-car-estate",
        label: this.$t("booking.steps.atPickup.txtLabel"),
        changeStatusAlert: this.$t(
          "booking.steps.atPickup.txtChangeStatusAlert"
        ),
      },
      onWayToDropoff: {
        status: "onWayToDropoff",
        btnText: this.$t("booking.steps.onWayToDropoff.btnText"),
        btnIcon: "mdi-car-estate",
        label: this.$t("booking.steps.onWayToDropoff.txtLabel"),
        changeStatusAlert: this.$t(
          "booking.steps.onWayToDropoff.txtChangeStatusAlert"
        ),
      },
      finished: {
        status: "finished",
        btnText: this.$t("booking.steps.finished.btnText"),
        btnIcon: "mdi-car-estate",
        label: this.$t("booking.steps.finished.txtLabel"),
        changeStatusAlert: this.$t(
          "booking.steps.finished.txtChangeStatusAlert"
        ),
      },
    };

    this.bookingStatusOpts = {
      active: {
        color: "success",
        label: this.$t("booking.bookingStatusOpts.txtActive"),
      },
      noShow: {
        color: "primary",
        label: this.$t("booking.bookingStatusOpts.txtNoShow"),
      },
      noProvided: {
        color: "warning",
        label: this.$t("booking.bookingStatusOpts.txtNoProvided"),
      },
      canceled: {
        color: "error",
        label: this.$t("booking.bookingStatusOpts.txtCanceled"),
      },
      done: {
        color: "success",
        label: this.$t("booking.bookingStatusOpts.txtDone"),
      },
    };

    // =>
    this.disabledNavigation();
    this.clearNavigationItems();
    this.activeBackBtn();
    this.fetchModel();
  },
};
</script>
